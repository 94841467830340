import service from "@/api/service";
import { GetRequest } from "@/api/role-template/request";
import { GetResponse } from "@/api/role-template/response";

/**
 * 権限ロールサンプル設定情報取得APIをコールします。
 *
 * @param getRequest 検索のリクエストボディ
 * @return GetResponse
 */
export async function get(getRequest: GetRequest) {
  const response = await service.post("/get-role-template", getRequest);
  return response.data as GetResponse;
}
