import service from "@/api/service";
import { GetResponse } from "@/api/role-menu/response";

/**
 * 権限ロール機能名一覧取得APIをコールします。
 *
 * @return GetResponse
 */
export async function get() {
  const response = await service.post("/get-role-menu");
  return response.data as GetResponse;
}
