import { Component, Mixins, Prop } from "vue-property-decorator";
import UlContentHeader from "@/components/UlContentHeader.vue";
import UlBreadcrumbs from "@/components/UlBreadcrumbs.vue";
import RedirectWithAlert from "@/models/mixins/redirect-with-alert";
import { GetRequest } from "@/api/role-template/request";
import {
  DetailRequest,
  RegisterRequest,
  RegisterAuthority
} from "@/api/role-setting/request";
import { GetItem as MenuItem } from "@/api/role-menu/response";
import RoleSettingShopGet from "@/store/role-setting-shop/get";
import RoleMenuGet from "@/store/role-menu/get";
import RoleTemplateGet from "@/store/role-template/get";
import RoleSettingDetail from "@/store/role-setting/detail";
import RoleSettingModify from "@/store/role-setting/modify";
import Flash, { ErrorAlert } from "@/store/common/flash";
import _ from "lodash";

const NEW_BREAD_CRUMBS = [
  { text: "設定", disabled: true },
  { text: "権限ロール", disabled: true },
  { text: "店舗一覧", disabled: true, to: { name: "authority" } },
  { text: "新規作成", disabled: true }
];

const EDIT_BREAD_CRUMBS = [
  { text: "設定", disabled: true },
  { text: "権限ロール", disabled: true },
  { text: "店舗一覧", disabled: true, to: { name: "authority" } },
  { text: "編集", disabled: true }
];

const ENABLE_BUTTON_STYLE: ButtonStyle = {
  outlined: false,
  color: "primary"
};

const DISABLE_BUTTON_STYLE: ButtonStyle = {
  outlined: true,
  color: "secondary"
};

interface ButtonStyles {
  [index: number]: ButtonStyle;
}

interface ButtonStyle {
  outlined: boolean;
  color: "primary" | "secondary";
}

@Component({
  components: {
    UlContentHeader,
    UlBreadcrumbs
  }
})
export default class NewEdit extends Mixins(RedirectWithAlert) {
  @Prop({ type: String, required: false })
  id?: string | null;

  // ------------
  // 固定値
  // ------------
  // タイトル
  headingMain = "権限ロール";
  headingSub = "Authority";

  // パンくず
  breadCrumbs = NEW_BREAD_CRUMBS;

  // テーブルヘッダ（VDataTableコンポーネントに渡す）
  tableHeaders = [
    { text: "機能", value: "ability", sortable: false },
    { text: "参照", value: "read", sortable: false },
    { text: "登録/更新", value: "write", sortable: false },
    { text: "削除", value: "delete", sortable: false }
  ];

  menuProps = {
    closeOnClick: false,
    closeOnContentClick: false,
    disableKeys: true,
    openOnClick: false,
    maxHeight: 304
  };

  // ------------
  // 変動値
  // ------------
  // 削除ダイアログの表示有無
  showDialog = false;

  // 選択したテンプレート名
  selectingTemplateName = "";

  // 選択したテンプレートID
  selectingTemplateId = null as number | null;

  // ローディングステータス
  isLoading = false;

  // ボタンのパラメータ
  buttonStyles = {
    1: DISABLE_BUTTON_STYLE,
    2: DISABLE_BUTTON_STYLE,
    3: DISABLE_BUTTON_STYLE
  } as ButtonStyles;

  inputParams = {
    /** 権限ロールID */
    id: null as number | null,
    /** 店舗ID */
    shopId: 0,
    /** 権限ロール名 */
    name: "",
    /** 権限情報の配列 */
    authority: [] as RegisterAuthority[]
  } as RegisterRequest;

  /**
   * 編集か否か
   */
  get isEdit() {
    return !!this.id;
  }

  get shopItems() {
    return RoleSettingShopGet.getItems.map(s => {
      return { id: Number(s.id), name: s.name };
    });
  }

  get menuItems() {
    return RoleMenuGet.getItems;
  }

  /**
   * createdライフサイクルフック
   */
  async created() {
    const shop = RoleSettingShopGet.get();
    const menu = RoleMenuGet.get();

    this.isLoading = true;
    let getErrorMessage = null as string | null;
    await Promise.all([menu, shop]);
    this.isLoading = false;
    if (!RoleSettingShopGet.isSuccess) {
      getErrorMessage = RoleSettingShopGet.getMessage;
    }
    if (!RoleTemplateGet.isSuccess) {
      getErrorMessage = RoleTemplateGet.getMessage;
    }
    if (!RoleMenuGet.isSuccess) {
      getErrorMessage = RoleMenuGet.getMessage;
    }
    if (getErrorMessage) {
      await Flash.setErrorNow({
        message: getErrorMessage,
        showReloadButton: true
      });
      return;
    }

    if (this.isEdit) {
      this.breadCrumbs = EDIT_BREAD_CRUMBS;
      this.isLoading = true;
      await RoleSettingDetail.detail({ id: this.id!! } as DetailRequest);
      this.isLoading = false;
      if (!RoleSettingDetail.isSuccess) {
        await Flash.setErrorNow({
          message: RoleSettingDetail.getMessage,
          showReloadButton: true
        } as ErrorAlert);
        return;
      }
      this.syncInputParams();
    } else {
      this.createAuthorities();
    }
  }

  /**
   * beforeDestroyライフサイクルフック
   */
  async beforeDestroy() {
    await RoleSettingShopGet.clearResponse();
    await RoleMenuGet.clearResponse();
    await RoleTemplateGet.clearResponse();
    await RoleSettingDetail.clearResponse();
    await RoleSettingModify.clearResponse();
  }

  /**
   * 特定のIDのread_flgを返却する
   *
   * @param item
   */
  getReadFlg(item: MenuItem) {
    const authority = this.findAuthority(item);
    return authority.readFlg;
  }

  /**
   * 特定のIDのwrite_flgを返却する
   *
   * @param item
   */
  getWriteFlg(item: MenuItem) {
    const authority = this.findAuthority(item);
    return authority.writeFlg;
  }

  /**
   * 特定のIDのdelete_flgを返却する
   *
   * @param item
   */
  getDeleteFlg(item: MenuItem) {
    const authority = this.findAuthority(item);
    return authority.deleteFlg;
  }

  /**
   * 特定のIDのread_flgを反転する
   *
   * @param item
   */
  switchReadFlg(item: MenuItem) {
    const authority = this.findAuthority(item);
    if (authority.readFlg === 0) {
      authority.readFlg = 1;
    } else {
      authority.readFlg = 0;
    }
    this.selectingTemplateId = null;
    this.resetButtonStyles();
  }

  /**
   * 特定のIDのwrite_flgを反転する
   *
   * @param item
   */
  switchWriteFlg(item: MenuItem) {
    const authority = this.findAuthority(item);
    if (authority.writeFlg === 0) {
      authority.writeFlg = 1;
    } else {
      authority.writeFlg = 0;
    }
    this.selectingTemplateId = null;
    this.resetButtonStyles();
  }

  /**
   * 特定のIDのdelete_flgを反転する
   *
   * @param item
   */
  switchDeleteFlg(item: MenuItem) {
    const authority = this.findAuthority(item);
    if (authority.deleteFlg === 0) {
      authority.deleteFlg = 1;
    } else {
      authority.deleteFlg = 0;
    }
    this.selectingTemplateId = null;
    this.resetButtonStyles();
  }

  /**
   * テンプレートボタンが押下された際のコールバック
   *
   * @param id
   * @param name
   */
  templateClickCallback(id: number, name: string) {
    if (this.selectingTemplateId === id) {
      return;
    }
    this.showDialog = true;
    this.selectingTemplateName = name;
    this.selectingTemplateId = id;
  }

  cancelSetAuthoritiesOnDialog() {
    this.showDialog = false;
    this.selectingTemplateId = null;
  }

  /**
   * ダイアログのワンクリック設定のコールバック
   */
  async doSetAuthoritiesOnDialog() {
    this.showDialog = false;
    if (!this.selectingTemplateId) {
      return;
    }

    this.isLoading = true;
    await RoleTemplateGet.get({ id: this.selectingTemplateId } as GetRequest);
    this.isLoading = false;
    if (!RoleTemplateGet.isSuccess) {
      this.selectingTemplateId = null;
      await Flash.setErrorNow({
        message: RoleTemplateGet.getMessage,
        showReloadButton: false
      } as ErrorAlert);
      return;
    }
    const authorities = RoleTemplateGet.getItems[0].authority;
    for (let i = 0; i < this.inputParams.authority.length; i++) {
      this.inputParams.authority[i].readFlg = authorities[i].readFlg;
      this.inputParams.authority[i].writeFlg = authorities[i].writeFlg;
      this.inputParams.authority[i].deleteFlg = authorities[i].deleteFlg;
    }
    this.resetButtonStyles();
    this.setButtonStylesEnable(this.selectingTemplateId);
  }

  /**
   * 登録処理
   */
  async register() {
    await Flash.clear();
    this.isLoading = true;
    await RoleSettingModify.register(this.createRegisterRequest());
    this.isLoading = false;
    if (!RoleSettingModify.isSuccess) {
      await Flash.setErrorNow({
        message: RoleSettingModify.getMessage,
        showReloadButton: false
      } as ErrorAlert);
      return;
    }
    this.redirectWithSuccessAlert("権限ロールを保存しました。", "/authority");
  }

  /**
   * APIのレスポンスパラメータをdataにsyncする
   */
  private syncInputParams() {
    const detail = RoleSettingDetail.getDetail;
    this.inputParams.id = Number(this.id);
    this.inputParams.shopId = detail.shopId;
    this.inputParams.name = detail.name;
    this.inputParams.authority = _.cloneDeep(detail.items);

    // RoleMenu にあって、Role にない項目を追加する
    for (const roleMenu of this.menuItems) {
      let authority = this.inputParams.authority.find(a => a.id === roleMenu.id);
      if (!authority) {
        this.inputParams.authority.push({
          id: roleMenu.id,
          writeFlg: 0,
          readFlg: 0,
          deleteFlg: 0
        } as RegisterAuthority);
      }
    }
  }

  /**
   * 認証設定の初期データを作成する（新規作成時）
   */
  private createAuthorities() {
    for (const item of this.menuItems) {
      this.inputParams.authority.push({
        id: item.id,
        writeFlg: 0,
        readFlg: 0,
        deleteFlg: 0
      } as RegisterAuthority);
    }
  }

  /**
   * dataから登録用のリクエストを作成する
   */
  private createRegisterRequest() {
    return _.cloneDeep(this.inputParams);
  }

  /**
   * 特定のIDの認証データを検索する
   *
   * @param item
   */
  private findAuthority(item: MenuItem) {
    let authority = this.inputParams.authority.find(a => a.id === item.id);
    if (!authority) {
      authority = {
        id: item.id,
        writeFlg: 0,
        readFlg: 0,
        deleteFlg: 0
      } as RegisterAuthority;
    }
    return authority;
  }

  /**
   * 全てのボタンの見た目を未選択状態にする
   */
  private resetButtonStyles() {
    this.buttonStyles[1] = DISABLE_BUTTON_STYLE;
    this.buttonStyles[2] = DISABLE_BUTTON_STYLE;
    this.buttonStyles[3] = DISABLE_BUTTON_STYLE;
  }

  /**
   * 特定のIDのボタンの見た目を選択状態にする
   *
   * @param id
   */
  private setButtonStylesEnable(id: number) {
    this.buttonStyles[id] = ENABLE_BUTTON_STYLE;
  }
}
